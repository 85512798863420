<template>
    <b-container v-if="mission" class="task-summary" fluid>
        <b-row>
            <b-col class="text-left">
                <div>
                    <div class="white-border">
                        <div class="header ml-4">
                            {{ $t('taskSummaryRoute.header') }}
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col class="ml-4 text-left">
                <div>
                    <font-awesome-icon :icon="['fas', 'bolt']" class="bolt mr-2" />
                    <strong class="main-summary-text">{{ $t('taskSummaryRoute.pending') }}</strong>
                </div>
                <div class="summary-description">
                    <strong> {{ $t('taskSummaryRoute.pendingInfo', { tasks: pendingTasks.length, totalTasks }) }}</strong>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="carousel-outer-container">
                <slider
                    ref="pendingTasksCarousel"
                    class="task-carousel"
                    :options="carouselOptions"
                    @slide="(data) => slideChanged(data, 'pendingTasks')"
                >
                    <slideritem
                        v-for="task in pendingTasks"
                        :key="task.id"
                        class="task-slide"
                    >
                        <task
                            :task="task"
                        />
                    </slideritem>
                </slider>
                <div
                    v-if="showPrevArrow(pendingTasks)"
                    class="arrow left"
                    @click="prevSlide('pendingTasks')"
                >
                    <font-awesome-icon :icon="['fas','chevron-left']" />
                </div>

                <div
                    v-if="showNextArrow(pendingTasks)"
                    class="arrow right"
                    @click="nextSlide('pendingTasks')"
                >
                    <font-awesome-icon :icon="['fas','chevron-right']" />
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-left mt-5">
                <div>
                    <div class="white-border" />
                </div>
            </b-col>
        </b-row>
        <!-- Pending Tasks End -->
        <b-row class="mt-3">
            <b-col class="ml-4 text-left">
                <div>
                    <font-awesome-icon :icon="['fas', 'hourglass-half']" class="hourglass-half mr-2" />
                    <strong class="main-summary-text">{{ $t('taskSummaryRoute.history') }}</strong>
                </div>
                <div class="summary-description">
                    <strong> {{ $t('taskSummaryRoute.historyInfo', { tasks: completedTasks.length, totalTasks }) }}</strong>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <task-history-table
                :completed-tasks="completedTasks"
            />
        </b-row>
    </b-container>
</template>

<script>
    import Task from '@/components/Task'
    import { slider, slideritem } from 'vue-concise-slider'
    import TaskHistoryTable from '@/components/TaskHistoryTable'
    import missionService from '@/services/mission-service'
    export default {
        name: 'TaskSummaryRoute',
        components: {
            Task,
            slider,
            slideritem,
            TaskHistoryTable
        },
        data() {
            return {
                completedTasksIndex: 0,
                pendingTasksIndex: 0,
                carouselOptions: {
                    pagination: false
                },
                mission: null
            }
        },
        computed: {
            completedTasks() {
                return this.mission.tasks.filter(t => t.completed === true)
            },
            pendingTasks() {
                return this.mission.tasks.filter(t => (!t.completed && !t.dependsOnParentTask) || (!t.completed && t.next))
            },
            totalTasks() {
                return this.mission.tasks.length
            }
        },
        created() {
            missionService.getMission(parseInt(this.$route.params.missionId))
                .then((res) => {
                    this.mission = res.data
                    this.$store.commit('setMission', res.data)
                })
        },
        methods: {
            prevSlide(carouselType) {
                if (carouselType === 'completedTasks') {
                    this.$refs.completedTasksCarousel.$emit('slidePre')
                } else if (carouselType === 'pendingTasks') {
                    this.$refs.pendingTasksCarousel.$emit('slidePre')
                }
            },
            nextSlide(carouselType) {
                if (carouselType === 'completedTasks') {
                    this.$refs.completedTasksCarousel.$emit('slideNext')
                } else if (carouselType === 'pendingTasks') {
                    this.$refs.pendingTasksCarousel.$emit('slideNext')
                }
            },
            hasBelowTwoItems(items) {
                return items.length < 2
            },
            showPrevArrow(items) {
                return this.pendingTasksIndex > 0 && !this.hasBelowTwoItems(items)
            },
            showNextArrow(items) {
                return this.pendingTasksIndex < items.length - 1 && !this.hasBelowTwoItems(items)
            },
            slideChanged(data, carouselType) {
                if (carouselType === 'completedTasks') {
                    this.completedTasksIndex = data.currentPage
                } else if (carouselType === 'pendingTasks') {
                    this.pendingTasksIndex = data.currentPage
                }
            },
            slideTo(num, carouselType) {
                if (carouselType === 'completedTasks') {
                    const renderedCarousel = this.$refs.completedTasksCarousel
                    if (renderedCarousel) {
                        this.completedTasksIndex = num
                        renderedCarousel.$emit('slideTo', num)
                    }
                } else if (carouselType === 'pendingTasks') {
                    const renderedCarousel = this.$refs.pendingTasksCarousel
                    if (renderedCarousel) {
                        this.pendingTasksIndex = num
                        renderedCarousel.$emit('slideTo', num)
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.task-summary {
    color: var(--main-content-text);
    padding: 1rem 1rem 2rem;
}

.header {
    font-weight: bold;
    font-size: 1.8em;
}

.white-border {
    border-bottom: 2px solid var(--main-content-text);
}

svg.check {
    font-weight: 700;
    font-size: 25px;
}

svg.check path {
    color: var(--correct);
}

svg.bolt path {
    color: var(--error);
}

svg.bolt {
    font-size: 25px;
}

svg.hourglass-half {
    font-size: 25px;
}

svg.hourglass-half path {
    color: var(--secondary-color);
}

.summary-description {
    font-size: 0.8em;
    width: max-content;
}

.main-summary-text {
    vertical-align: top;
}

.task {
    outline: none;
}

.carousel-outer-container {
    position: relative;
    padding: 0 2rem;
}

.task-carousel {
    margin-top: 1rem;
    padding: 0 1rem;
}

.arrow {
    display: block;
    color: var(--logo-highlight-color);
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 0 0.5rem;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

.slider-container {
    white-space: normal;
    height: unset;
}

.slider-item {
    height: unset;
}

.task-slide {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    margin-right: 1.5rem;
    width: 100%;
    max-width: 280px;
    min-height: 460px;
}

.carousel-pagination {
    padding: 1.5rem 0;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--light-highlight-color);
    cursor: pointer;
}

.bullet + .bullet {
    margin-left: 1rem;
}

.bullet.active {
    background: var(--logo-highlight-color);
}

@media screen and(min-width: 768px) {
    .task-carousel {
        margin-top: 2rem;
    }

    .carousel-outer-container {
        padding: 0 2.25rem;
    }
}

</style>

<style lang="scss">
    tr th {
        vertical-align: baseline !important;
    }
</style>
